<template>
  <section class="section-container">
    <v-row class="signin">
      <v-col cols="8" class="left">
        <h1>Logo</h1>
      </v-col>
      <v-col cols="4" class="right">
        <h2>INICIAR SESIÓN</h2>
          <v-form @submit.prevent="submit">
            
              <v-text-field
                v-model="email"
                label="Correo electrónico"
                required
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                outlined
                dark
                filled
                dense
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Contraseña"
                :error-messages="passwordErrors"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                :type="showPass ? 'text' : 'password'"
              ></v-text-field>
            <div class="text-center">
              <v-btn class="signin-btn" type="submit" rounded color="white" dark :loading="loadingButton">
                Ingresar
              </v-btn>
            </div>
          </v-form>
          <v-alert 
            v-model="errorSession"
            type="error" 
            class="mt-3" 
            dismissible
            border="left">
            Correo electrónico o contraseña incorrectos.
          </v-alert>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
export default {
  data: () => ({
    email: '',
    password: null,
    showPass: false,
    errorSession: false,
    loadingButton: false
  }),
  computed: {
    emailErrors(){
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('El correo electrónico debe ser válido.')
      !this.$v.email.required && errors.push('El correo electrónico es requerido.')
      return errors
    },
    passwordErrors(){
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('La contraseña es requerida.')
      return errors
    }
  },
  validations: {
    email: {required, email},
    password: {required}
  },
  methods: {
    submit() {
      this.$v.$touch();

      if (!this.$v.$invalid){
        
        this.loadingButton = true;
        const customActions = {
          create: {method: 'POST'}                   
        };
        this.resource = this.$resource('users/sign_in',{},customActions);

        this.resource.create({
          data: {
            attributes: {
              email: this.email,
              password: this.password
            }
          }
        })
        .then(function(data){
          if (data.status == 200) {
            this.updateCrypto(data.body.token);
            this.updateUser(data.body);
            this.updateClientSelect({id:0,name:'Cliente no seleccionado'})
            this.$router.push({ name: 'Dashboard' });
          }
          
          this.loadingButton = false;
          
        }).catch(function(err){
          if (err.status == 422) {
            this.errorSession = true;
            //console.log('Error', err)
            this.loadingButton = false;
          }
        });
      }


      
    },
    clear() {
      // you can use this method to clear login form
      this.email = ''
      this.password = null
      this.$refs.observer.reset()
    }
  }
}
</script>